import { LegacyRef, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate, Link as RouteLink, useParams } from "react-router-dom";

import { Container } from "@mui/material";
import {
  ActionIcon,
  CheckIcon,
  Radio,
  Group,
  Badge,
  TextInput,
  NumberInput,
  Select,
  Popover,
  Alert,
  Button,
  rem,
  Loader,
  Text,
  TagsInput,
  Modal,
  Divider,
  Pill,
  Table,
} from "@mantine/core";

import {
  IconExclamationCircle,
  IconPencil,
  IconRefresh,
  IconPercentage,
  IconTrash,
  IconCurrencyNaira,
  IconPackage,
  IconPlus,
  IconUpload,
  IconX,
  IconPhoto,
  IconArrowLeft,
} from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { Carousel } from "@mantine/carousel";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
// RICH TEXT EDITOR IMPORTS
import {
  RichTextEditor,
  Link,
  useRichTextEditorContext,
} from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";

import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
// END RICH TEXT EDITOR IMPORTS

import { motion } from "framer-motion";

import {
  PerformRequest,
  UploadFiles,
  usePerformRequest,
} from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import {
  ExtendedFile,
  ImageProps,
  PriceProps,
  ProductProps,
} from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  checkIsValidNumber,
  generateRandomString,
} from "../../Lib/Methods";
import { ProductTypes } from "../../Lib/Data";

import "./styles.scss";
import dayjs from "dayjs";

const SingleProduct: React.FC = () => {
  const navigate = useNavigate();
  const { productID } = useParams();
  if (!productID) {
    navigate("/dashboard/products");
  }
  const {
    data,
    reloadData: reloadProduct,
    isLoading: isFetchingProduct,
    status,
  } = usePerformRequest<NonPaginatedResponse<ProductProps>>({
    method: "GET",
    url: `${Endpoints.GetSingleProduct}/${productID}`,
  });

  const [currentMode, setCurrentMode] = useState<"edit" | "view">("view");

  const productForm = useForm({
    initialValues: {
      name: "",
      type: "",
      brand: "",
      discount: 0,
      colour: [] as string[],
      images: [] as ImageProps[],
      featuredImage: "",
      description: "",
      prices: [] as PriceProps[],
    },
    validate: {
      name: (value) => (value.length < 4 ? "Enter product name" : null),
      type: (value) =>
        !value || value.length === 0 ? "Select product type!" : null,
      colour: (value) =>
        !value || value.length === 0 ? "Enter at least one colour!" : null,
      prices: (values) => {
        const invalidValues = values.filter(
          (v) =>
            !v.size ||
            v.size.length === 0 ||
            !checkIsValidNumber(v.stock) ||
            !checkIsValidNumber(v.price)
        );
        return invalidValues.length > 0 ? "Ensure prices are filled!" : null;
      },
      description: () =>
        editor?.getText().length === 0 ? "Enter product description" : null,
      featuredImage: (value) =>
        value.length === 0 ? "Select featured image!" : null,
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: productForm.values.description,
    onUpdate: (e) => {
      productForm.setFieldValue("description", e.editor.getHTML());
    },
  });

  const editorDescriptionRef = useRef() as LegacyRef<HTMLDivElement>;

  const SetOriginalProductData = async () => {
    if (data && data.data) {
      const {
        name,
        type,
        discount,
        brand,
        colour,
        images,
        featuredImage,
        description,
        prices,
      } = data.data;
      productForm.setValues({
        name,
        type,
        discount,
        brand: brand ?? "",
        colour: colour ?? [],
        images: (images as any[]) ?? ([] as any[]),
        featuredImage,
        description,
        prices,
      });
      editor?.commands?.setContent(description);
    }
  };
  const CancelEditing = () => {
    setCurrentMode("view");

    setFilesToUpload([]);
    SetOriginalProductData();
  };
  useEffect(() => {
    SetOriginalProductData();
  }, [data]);

  useEffect(() => {
    if (currentMode === "edit" && data && data.data) {
      productForm.setFieldValue("description", data?.data.description);
    }
  }, [currentMode, data]);

  const [filesToUpload, setFilesToUpload] = useState<ExtendedFile[]>([]);

  const [filesError, setFilesError] = useState<boolean>(false);

  const [isUpdating, setUpdating] = useState<boolean>(false);
  const UpdateProduct = async () => {
    const {
      name,
      type,
      discount,
      colour,
      brand,
      featuredImage,
      description,
      prices,
      images: originalImages,
    } = productForm.values;
    setFilesError(filesToUpload.length === 0 && originalImages.length === 0);
    const { hasErrors } = productForm.validate();
    if (filesToUpload.length === 0 && originalImages.length === 0) {
      DefaultErrorNotification("You must have at least ONE image");
    } else if (featuredImage.length === 0) {
      DefaultErrorNotification("Please select ONE image to be featured");
    }

    if (!hasErrors) {
      const imageValidity =
        featuredImage.length > 0 &&
        (originalImages.length > 0 || filesToUpload.length > 0);
      if (imageValidity) {
        setUpdating(true);
        if (filesToUpload.length > 0) {
          const a = await UploadFiles(filesToUpload);
          if (a && a.status === 201) {
            const images = a.data.map((f) => {
              return {
                url: f.path,
                cloudinaryID: f.cloudinaryID,
                id: f.id,
              };
            });
            const featuredImageFile = filesToUpload.find(
              (f) => f.id === featuredImage
            );

            const featuredImageFileIndex = filesToUpload.indexOf(
              featuredImageFile as ExtendedFile
            );
            let newFeaturedImage = "";
            if (featuredImage && featuredImage.includes(".")) {
              newFeaturedImage = featuredImage;
            } else {
              newFeaturedImage = images[featuredImageFileIndex].cloudinaryID;
            }
            const r = await PerformRequest<NonPaginatedResponse<any>>({
              method: "POST",
              route: Endpoints.EditProduct,
              data: {
                id: productID,
                name,
                type,
                colour,
                brand,
                images: [...originalImages, ...images],
                prices,
                featuredImage: newFeaturedImage,
                discount,
                description,
              },
            });
            setUpdating(false);
            if (r && r.status) {
              if (r.status === 200) {
                DefaultSuccessNotification();
                setFilesToUpload([]);
                reloadProduct();
                setCurrentMode("view");
              } else {
                DefaultErrorNotification("Could not update product!");
              }
            }
          } else {
            setUpdating(false);
            DefaultErrorNotification("Could not upload images!");
          }
        } else {
          const r = await PerformRequest<NonPaginatedResponse<any>>({
            method: "POST",
            route: Endpoints.EditProduct,
            data: {
              id: productID,
              name,
              type,
              colour,
              brand,
              images: originalImages,
              prices,
              featuredImage,
              discount,
              description,
            },
          });
          setUpdating(false);
          if (r && r.status) {
            if (r.status === 200) {
              DefaultSuccessNotification();
              productForm.reset();
              setFilesToUpload([]);
              reloadProduct();
              setCurrentMode("view");
            } else {
              DefaultErrorNotification("Could not update product!");
            }
          }
        }
      }
    }
  };

  const [isDeleting, setDeleting] = useState<boolean>(false);
  const DeleteProduct = async () => {
    setDeleting(true);
    const r = await PerformRequest<NonPaginatedResponse<any>>({
      method: "DELETE",
      route: `${Endpoints.DeleteProduct}/${productID}`,
    });
    setDeleting(false);
    if (r && r.status === 200) {
      reloadProduct();
      DefaultSuccessNotification("Product removed from platform!");
    }
  };
  return (
    <div className="single-product-container">
      {isFetchingProduct ? (
        <Group align="center" mt={30} justify="center">
          <Loader size={120} />
        </Group>
      ) : (
        <>
          {data?.data ? (
            <div className="product flex-col">
              <div className="flex-row align-center justify-between">
                <Text fw={600} fz="xl">
                  Product Information
                </Text>
                <div className="flex-row align-center">
                  <ActionIcon
                    size="lg"
                    disabled={currentMode === "edit"}
                    mr={10}
                  >
                    <IconRefresh size={19} />
                  </ActionIcon>
                  <Popover width={250} position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <ActionIcon
                        color="red"
                        size="lg"
                        disabled={currentMode === "edit"}
                        mr={10}
                      >
                        <IconTrash size={19} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Text size="lg">Confirm</Text>
                      <Text size="sm">This action cannot be undone</Text>
                      <Button
                        onClick={DeleteProduct}
                        fullWidth
                        color="red"
                        mt={10}
                        disabled={isDeleting}
                      >
                        Delete Product
                      </Button>
                    </Popover.Dropdown>
                  </Popover>
                  <Button
                    variant="outline"
                    ml={10}
                    disabled={currentMode === "edit" || isDeleting}
                    leftSection={<IconPencil size={19} />}
                    onClick={() => {
                      setCurrentMode("edit");
                      setFilesToUpload([]);
                      SetOriginalProductData();
                      productForm.setFieldValue(
                        "description",
                        data?.data.description
                      );
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
              {currentMode === "edit" ? (
                <Modal
                  fullScreen
                  centered
                  title="Update product information"
                  opened={currentMode === "edit"}
                  onClose={CancelEditing}
                >
                  <div className="product-modal flex-col justify-between">
                    <div className="flex-row align-center justify-between width-100">
                      <TextInput
                        label="Name"
                        w="48%"
                        placeholder="Enter product name"
                        {...productForm.getInputProps("name")}
                      />
                      <TextInput
                        label="Product Brand (optional)"
                        w="48%"
                        placeholder="Enter product brand"
                        {...productForm.getInputProps("brand")}
                      />
                    </div>
                    <br />
                    <div className="flex-row align-center justify-between width-100">
                      <Select
                        data={ProductTypes}
                        w="48%"
                        label="Product Type"
                        placeholder="Select product type"
                        {...productForm.getInputProps("type")}
                      />
                      <NumberInput
                        hideControls
                        min={0}
                        leftSection={<IconPercentage size={18} />}
                        placeholder="Enter discount"
                        label="Discount (optional)"
                        disabled
                        w="48%"
                        {...productForm.getInputProps("discount")}
                      />
                    </div>
                    <br />
                    <TagsInput
                      w="100%"
                      label="Press Enter to submit a colour"
                      placeholder="Enter colours e.g White"
                      {...productForm.getInputProps("colour")}
                    />
                    <Divider w="100%" mt={22} mb={15} />
                    <Text fw={500}>Prices</Text>
                    {productForm.values.prices.map((priceObject, index) => {
                      const ChangeProperty = (
                        property: "size" | "price" | "stock",
                        value: string | number
                      ) => {
                        const { prices } = productForm.values;
                        const priceIndex = productForm.values.prices.find(
                          (p) => p.id === priceObject.id
                        );
                        if (priceIndex) {
                          let tempPrices = prices;
                          if (property === "size") {
                            tempPrices[prices.indexOf(priceIndex)].size =
                              value as string;
                            productForm.setFieldValue("prices", tempPrices);
                          } else if (property === "price") {
                            tempPrices[prices.indexOf(priceIndex)].price =
                              value as number;
                            productForm.setFieldValue("prices", tempPrices);
                          } else if (property === "stock") {
                            tempPrices[prices.indexOf(priceIndex)].stock =
                              value as number;
                            productForm.setFieldValue("prices", tempPrices);
                          }
                        }
                      };
                      const RemovePrice = () => {
                        const { prices } = productForm.values;
                        if (prices.length > 1) {
                          productForm.setFieldValue(
                            "prices",
                            prices.filter((p) => p.id !== priceObject.id)
                          );
                        } else {
                          DefaultErrorNotification(
                            "You must have at least ONE variant!",
                            "Unable To Remove"
                          );
                        }
                      };
                      return (
                        <div
                          className="flex-row align-end justify-between width-100 price-row"
                          key={priceObject.id}
                        >
                          <TextInput
                            placeholder="Size e.g 20 Inches"
                            w="28%"
                            label="Size"
                            defaultValue={priceObject.size}
                            onChange={(e) => {
                              ChangeProperty("size", e.target.value);
                            }}
                          />
                          <NumberInput
                            hideControls
                            min={0}
                            leftSection={<IconCurrencyNaira size={18} />}
                            placeholder="Enter Price"
                            w="28%"
                            defaultValue={priceObject.price}
                            onChange={(e) => {
                              ChangeProperty("price", e);
                            }}
                            label="Price"
                          />
                          <NumberInput
                            hideControls
                            min={0}
                            leftSection={<IconPackage size={18} />}
                            placeholder="Amount in stock"
                            defaultValue={priceObject.stock}
                            onChange={(e) => {
                              ChangeProperty("stock", e);
                            }}
                            w="28%"
                            label="Stock"
                          />
                          <ActionIcon
                            variant="subtle"
                            h={35}
                            w={35}
                            onClick={RemovePrice}
                          >
                            <IconTrash size={15} />
                          </ActionIcon>
                        </div>
                      );
                    })}
                    <Button
                      my={20}
                      leftSection={<IconPlus size={15} />}
                      style={{
                        padding: 0,
                        width: 120,
                      }}
                      h={35}
                      variant="outline"
                      onClick={() => {
                        productForm.setFieldValue("prices", [
                          ...productForm.values.prices,
                          {
                            size: "",
                            id: generateRandomString(12),
                            stock: 0,
                            price: 0,
                          },
                        ]);
                      }}
                    >
                      Add Variant
                    </Button>

                    <Divider w="100%" mt={22} mb={15} />
                    <Text fw={500} mb={10}>
                      Product Description
                    </Text>

                    <RichTextEditor
                      editor={editor}
                      ref={editorDescriptionRef}
                      style={{
                        borderColor: productForm.errors.description
                          ? "var(--mantine-color-red-6)"
                          : "#CED4DA",
                      }}
                    >
                      <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Bold />
                          <RichTextEditor.Italic />
                          <RichTextEditor.Underline />
                          <RichTextEditor.Strikethrough />
                          <RichTextEditor.ClearFormatting />
                          <RichTextEditor.Highlight />
                          <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.H1 />
                          <RichTextEditor.H2 />
                          <RichTextEditor.H3 />
                          <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Blockquote />
                          <RichTextEditor.Hr />
                          <RichTextEditor.BulletList />
                          <RichTextEditor.OrderedList />
                          <RichTextEditor.Subscript />
                          <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Link />
                          <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.AlignLeft />
                          <RichTextEditor.AlignCenter />
                          <RichTextEditor.AlignJustify />
                          <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                          <RichTextEditor.Undo />
                          <RichTextEditor.Redo />
                        </RichTextEditor.ControlsGroup>
                      </RichTextEditor.Toolbar>

                      <RichTextEditor.Content mih={120} />
                    </RichTextEditor>
                    <Divider w="100%" mt={22} mb={15} />
                    <Text mb={10} fw={500}>
                      Upload Images
                    </Text>
                    <Dropzone
                      style={{
                        borderColor: filesError
                          ? "var(--mantine-color-red-6)"
                          : "#868E96",
                      }}
                      color="blue"
                      onDrop={(files) => {
                        const extendedFiles: ExtendedFile[] = files.map((f) => {
                          let obj: any = f;
                          obj.id = generateRandomString(12);

                          return obj;
                        });

                        setFilesToUpload((prevFiles) => [
                          ...prevFiles,
                          ...extendedFiles,
                        ]);
                      }}
                      onReject={(files) => console.log("rejected files", files)}
                      maxSize={5 * 1024 ** 2}
                      accept={IMAGE_MIME_TYPE}
                    >
                      <Group
                        justify="center"
                        gap="xl"
                        mih={100}
                        style={{ pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconUpload
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-blue-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-red-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-dimmed)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Idle>

                        <div>
                          <Text size="xl" inline>
                            Drag images here or click to select files
                          </Text>
                          <Text size="sm" c="dimmed" inline mt={7}>
                            Attach as many files as you like, each file should
                            not exceed 5mb
                          </Text>
                        </div>
                      </Group>
                    </Dropzone>
                    <motion.div
                      initial={false}
                      animate={{
                        height:
                          filesToUpload.length > 0 ||
                          productForm.values.images.length > 0
                            ? 200
                            : 0,
                        display:
                          filesToUpload.length > 0 ||
                          productForm.values.images.length > 0
                            ? "flex"
                            : "none",
                      }}
                    >
                      <Carousel
                        my={20}
                        slideSize="30%"
                        height={200}
                        slideGap="md"
                        withControls
                        withIndicators={false}
                        maw="100%"
                        miw="100%"
                      >
                        {productForm.values.images.map((image) => {
                          const { images } = productForm.values;

                          return (
                            <Carousel.Slide key={image.cloudinaryID} maw={280}>
                              <div
                                className="image-container flex-col justify-between"
                                style={{
                                  backgroundImage: `url(${image.url})`,
                                }}
                              >
                                <div className="flex-row align-center">
                                  <ActionIcon
                                    size="lg"
                                    color="white"
                                    onClick={() => {
                                      productForm.setFieldValue(
                                        "images",
                                        images.filter(
                                          (i) =>
                                            i.cloudinaryID !==
                                            image.cloudinaryID
                                        )
                                      );
                                      if (
                                        productForm.values.featuredImage ===
                                        image.cloudinaryID
                                      ) {
                                        productForm.setFieldValue(
                                          "featuredImage",
                                          ""
                                        );
                                      }
                                    }}
                                    mr={10}
                                  >
                                    <IconTrash color="red" size={16} />
                                  </ActionIcon>
                                  <Radio
                                    styles={{
                                      radio: {
                                        borderColor: "#40C057",
                                      },
                                    }}
                                    classNames={{
                                      radio: "pointer",
                                    }}
                                    icon={CheckIcon}
                                    color="green"
                                    checked={
                                      productForm.values.featuredImage ===
                                      image.cloudinaryID
                                    }
                                    onChange={(e) => {
                                      if (e.currentTarget.checked) {
                                        productForm.setFieldValue(
                                          "featuredImage",
                                          image.cloudinaryID
                                        );
                                      }
                                    }}
                                    size="lg"
                                  />
                                </div>
                                {productForm.values.featuredImage ===
                                  image.cloudinaryID && (
                                  <div className="flex-row align-center width-100 justify-center">
                                    <Badge
                                      color="green"
                                      size="lg"
                                      w={200}
                                      variant="filled"
                                    >
                                      SELECTED
                                    </Badge>
                                  </div>
                                )}
                              </div>
                            </Carousel.Slide>
                          );
                        })}
                        {filesToUpload.map((file) => {
                          const imgURL = URL.createObjectURL(file);
                          return (
                            <Carousel.Slide key={file.id} maw={280}>
                              <div
                                className="image-container flex-col justify-between"
                                style={{
                                  backgroundImage: `url(${imgURL})`,
                                }}
                              >
                                <div className="flex-row align-center">
                                  <ActionIcon
                                    size="lg"
                                    color="white"
                                    onClick={() => {
                                      setFilesToUpload(
                                        filesToUpload.filter(
                                          (f) => f.id !== file.id
                                        )
                                      );
                                      if (
                                        productForm.values.featuredImage ===
                                        file.id
                                      ) {
                                        productForm.setFieldValue(
                                          "featuredImage",
                                          ""
                                        );
                                      }
                                    }}
                                    mr={10}
                                  >
                                    <IconTrash color="red" size={16} />
                                  </ActionIcon>
                                  <Radio
                                    styles={{
                                      radio: {
                                        borderColor: "#40C057",
                                      },
                                    }}
                                    classNames={{
                                      radio: "pointer",
                                    }}
                                    icon={CheckIcon}
                                    color="green"
                                    checked={
                                      productForm.values.featuredImage ===
                                      file.id
                                    }
                                    onChange={(e) => {
                                      if (e.currentTarget.checked) {
                                        productForm.setFieldValue(
                                          "featuredImage",
                                          file.id
                                        );
                                      }
                                    }}
                                    size="lg"
                                  />
                                </div>
                                {productForm.values.featuredImage ===
                                  file.id && (
                                  <div className="flex-row align-center width-100 justify-center">
                                    <Badge
                                      color="green"
                                      size="lg"
                                      w={200}
                                      variant="filled"
                                    >
                                      SELECTED
                                    </Badge>
                                  </div>
                                )}
                              </div>
                            </Carousel.Slide>
                          );
                        })}
                      </Carousel>
                    </motion.div>

                    <div className="flex-row width-100 justify-between">
                      <Popover
                        width={250}
                        position="bottom"
                        withArrow
                        shadow="md"
                      >
                        <Popover.Target>
                          <Button
                            my={30}
                            mt={50}
                            variant="outline"
                            fullWidth
                            loading={isUpdating}
                            color="red"
                          >
                            Cancel
                          </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Text size="lg">Confirm</Text>
                          <Text size="sm">Your changes won't be saved</Text>
                          <Button
                            onClick={CancelEditing}
                            fullWidth
                            color="red"
                            mt={10}
                          >
                            Cancel
                          </Button>
                        </Popover.Dropdown>
                      </Popover>
                      &nbsp; &nbsp;
                      <Button
                        my={30}
                        mt={50}
                        fullWidth
                        onClick={UpdateProduct}
                        loading={isUpdating}
                      >
                        Update Product
                      </Button>
                    </div>
                  </div>
                </Modal>
              ) : (
                <div className="flex-col width-100 body">
                  <div className="item-row">
                    <Badge radius="xs">Created On</Badge>
                    <Text className="value" fz={16} fw={500}>
                      {dayjs(data.data.dateCreated).format("dddd DD MMMM YYYY")}
                    </Text>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Name</Badge>
                    <Text className="value" fz={16} fw={500}>
                      {data.data.name}
                    </Text>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Brand</Badge>
                    <Text className="value" fz={16} fw={500} tt="capitalize">
                      {data.data.brand ? data.data.brand : "Unspecified"}
                    </Text>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Type</Badge>
                    <Text className="value" fz={16} fw={500} tt="capitalize">
                      {data.data.type}
                    </Text>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Description</Badge>
                    <Text className="value" fz={16} fw={500}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.data.description,
                        }}
                      ></div>
                    </Text>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Colours</Badge>
                    <Text className="value" fz={16} fw={500}>
                      {data.data.colour?.map((c, index) => {
                        return (
                          <Pill
                            size="lg"
                            key={index}
                            fw={500}
                            fz={14}
                            radius="xs"
                          >
                            {c}
                          </Pill>
                        );
                      })}
                    </Text>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Images</Badge>
                    <Carousel
                      my={20}
                      slideSize="30%"
                      height={200}
                      slideGap="md"
                      withControls
                      withIndicators={false}
                      maw="100%"
                      miw="100%"
                    >
                      {data.data.images.map((image) => {
                        return (
                          <Carousel.Slide
                            key={image.cloudinaryID}
                            maw={280}
                            style={{
                              border:
                                data.data.featuredImage === image.cloudinaryID
                                  ? "1px solid var(--mantine-color-green-5)"
                                  : "",
                              borderRadius: 10,
                              marginLeft: 10,
                              marginRight: 10,
                            }}
                          >
                            <div
                              className="image-container flex-col justify-between"
                              style={{
                                backgroundImage: `url(${image.url})`,
                              }}
                            >
                              <div className="flex-row align-center">
                                <Radio
                                  styles={{
                                    radio: {
                                      borderColor: "#40C057",
                                    },
                                  }}
                                  icon={CheckIcon}
                                  color="green"
                                  checked={
                                    data.data.featuredImage ===
                                    image.cloudinaryID
                                  }
                                  size="lg"
                                />
                              </div>
                            </div>
                          </Carousel.Slide>
                        );
                      })}
                    </Carousel>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Prices</Badge>
                    <Table
                      mt={4}
                      mb={20}
                      verticalSpacing="md"
                      withColumnBorders
                      withTableBorder
                    >
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>Size</Table.Th>
                          <Table.Th>Price (₦)</Table.Th>
                          <Table.Th>Stock</Table.Th>
                        </Table.Tr>
                      </Table.Thead>

                      <Table.Tbody>
                        {data.data.prices.map((price) => {
                          return (
                            <Table.Tr key={price.id}>
                              <Table.Td>
                                <Text>{price.size}</Text>
                              </Table.Td>
                              <Table.Td>
                                <Text>{price.price}</Text>
                              </Table.Td>
                              <Table.Td>
                                <Text>{price.stock}</Text>
                              </Table.Td>
                            </Table.Tr>
                          );
                        })}
                      </Table.Tbody>
                    </Table>
                  </div>
                  <div className="item-row">
                    <Badge radius="xs">Discount</Badge>
                    <Text className="value" fz={16} fw={500}>
                      {data.data.discount ?? 0}%
                    </Text>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Container maxWidth="sm">
              <Alert title="Not Found" mt={30}>
                <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                <Text>Product does not exist!</Text>
                <RouteLink to="/dashboard/products">
                  <Button mt={13} leftSection={<IconArrowLeft size={15} />}>
                    All Products
                  </Button>
                </RouteLink>
              </Alert>
            </Container>
          )}
        </>
      )}
    </div>
  );
};

export default SingleProduct;

import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import { Loader, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import Cookies from "js-cookie";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";

import "./index.scss";

import { PerformRequest } from "./Lib/usePerformRequest";
import { Endpoints } from "./Lib/Endpoints";
import MiniLoader from "./Components/MiniLoader";
import RingLoader from "./Components/RingLoader";

import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Products from "./Pages/Products";
import SingleProduct from "./Pages/SingleProduct";
import Orders from "./Pages/Orders";
import SingleOrder from "./Pages/SingleOrder";
import Clients from "./Pages/Clients";
import SingleClient from "./Pages/SingleClient";
import ForgotPassword from "./Pages/ForgotPassword";

function RedirectToAppropriatePage() {
  const navigate = useNavigate();
  const IsAdminAuth = async () => {
    const r = await PerformRequest({
      method: "GET",
      route: Endpoints.GetProfile,
    });
    const { data, status, message } = r;
    if (status === 200) {
      navigate("/dashboard/products");
    } else if (status === 401) {
      navigate("/login");
      Cookies.remove("token");
    }
  };
  useEffect(() => {
    IsAdminAuth();
  }, []);
  return (
    <div>
      <MiniLoader size={150} />
    </div>
  );
}
function App() {
  return (
    <MantineProvider
      theme={{
        components: {
          Loader: Loader.extend({
            defaultProps: {
              loaders: { ...Loader.defaultLoaders, ring: RingLoader },
              type: "ring",
            },
          }),
        },
      }}
    >
      <Notifications position="top-right" />
      <Router>
        <Routes>
          <Route index path="/" element={<RedirectToAppropriatePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="/dashboard/products" element={<Products />} />
            <Route
              path="/dashboard/product/:productID"
              element={<SingleProduct />}
            />
            <Route path="/dashboard/orders" element={<Orders />} />
            <Route path="/dashboard/order/:orderID" element={<SingleOrder />} />
            <Route path="/dashboard/clients" element={<Clients />} />
            <Route
              path="/dashboard/client/:clientID"
              element={<SingleClient />}
            />
            <Route path="/dashboard/settings" element={<Products />} />
          </Route>
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;

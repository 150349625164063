import { useEffect, useState } from "react";

import { Link as RouteLink, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import {
  Text,
  Button,
  Table,
  Pagination,
  Group,
  Alert,
  Loader,
} from "@mantine/core";

import { IconReload, IconExclamationCircle } from "@tabler/icons-react";
import Cookies from "js-cookie";

import { usePerformRequest } from "../../Lib/usePerformRequest";
import { OrderProps, PaginationProps } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { PaginatedResponse } from "../../Lib/Responses";

import { getFinancialValueFromNumeric } from "../../Lib/Methods";

import "./styles.scss";
import dayjs from "dayjs";

const Orders: React.FC = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const [orders, setOrders] = useState<OrderProps[]>([]);

  const { data, reloadData, isLoading, status } = usePerformRequest<
    PaginatedResponse<OrderProps[]>
  >({
    method: "POST",
    body: pagination,
    url: Endpoints.GetOrders,
  });
  if (status === 401) {
    Cookies.remove("token");
    navigate("/login");
  }
  useEffect(() => {
    if (data && data?.data) {
      setOrders(data.data);
    }
  }, [data]);

  useEffect(() => {
    reloadData();
  }, [pagination]);

  return (
    <div>
      <div className="header flex-row width-100 justify-between align-center">
        <Text fw={600} fz="xl" tt="capitalize">
          Orders
        </Text>
        <div className="flex-row align-center justify-center">
          <Button
            h={37}
            onClick={() => {
              reloadData();
            }}
            loading={isLoading}
            leftSection={<IconReload size={15} />}
          >
            Refresh
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Group align="center" mt={30} justify="center">
          <Loader size={120} />
        </Group>
      ) : (
        <>
          <Table mt={20} verticalSpacing="md" withColumnBorders withTableBorder>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Date</Table.Th>
                <Table.Th>Reference</Table.Th>
                <Table.Th>Amount</Table.Th>
                <Table.Th>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {orders.map((order, index) => {
                return (
                  <Table.Tr key={order.id}>
                    <Table.Td>
                      {dayjs(order.dateCreated).format("DD MMMM YYYY")}
                    </Table.Td>
                    <Table.Td>{order.reference}</Table.Td>
                    <Table.Td>
                      ₦{getFinancialValueFromNumeric(order.amount)}
                    </Table.Td>
                    <Table.Td>
                      <RouteLink to={`/dashboard/order/${order.id}`}>
                        <Button variant="subtle">Details</Button>
                      </RouteLink>
                    </Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
          {orders?.length === 0 && (
            <Container maxWidth="sm">
              <Alert title="No results" mt={30}>
                <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                <Text>No orders found!</Text>
              </Alert>
            </Container>
          )}
        </>
      )}

      <div className="flex-row align-center justify-center width-100">
        <Pagination
          mt={20}
          disabled={isLoading}
          value={pagination.page}
          onChange={(e) => setPagination({ ...pagination, page: e })}
          total={
            data && data.totalRecords
              ? data.totalRecords < 21
                ? 1
                : Math.ceil(data.totalRecords / 20)
              : 1 ?? 10
          }
        />
      </div>
    </div>
  );
};
export default Orders;

import { useEffect, useState } from "react";

import { Link as RouteLink, useLocation } from "react-router-dom";

import { Container } from "@mui/material";

import {
  Text,
  Button,
  Table,
  ActionIcon,
  TextInput,
  Pagination,
  Modal,
  Select,
  TagsInput,
  NumberInput,
  Divider,
  Group,
  rem,
  Radio,
  CheckIcon,
  Badge,
  Alert,
  Loader,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { Carousel } from "@mantine/carousel";

import {
  IconCurrencyNaira,
  IconPlus,
  IconReload,
  IconSearch,
  IconTrash,
  IconX,
  IconUpload,
  IconPhoto,
  IconPercentage,
  IconPackage,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { color, motion } from "framer-motion";
// RICH TEXT EDITOR IMPORTS
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";

import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
// END RICH TEXT EDITOR IMPORTS
import {
  PerformRequest,
  UploadFiles,
  usePerformRequest,
} from "../../Lib/usePerformRequest";
import { ExtendedFile, PaginationProps, ProductProps } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { NonPaginatedResponse, PaginatedResponse } from "../../Lib/Responses";
import LoaderComp from "../../Components/LoaderComp";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  checkIsValidNumber,
  generateRandomString,
  getEllipsisWithString,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";

import "./styles.scss";
import { ProductTypes } from "../../Lib/Data";

export default function Products() {
  const [pagination, setPagination] = useState<PaginationProps>({
    page: 1,
    limit: 20,
  });

  const [currentResults, setCurrentResults] = useState<"search" | "all">("all");
  const [isSearching, setSearching] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductProps[]>([]);

  const { data, reloadData, isLoading, status } = usePerformRequest<
    PaginatedResponse<ProductProps[]>
  >({
    method: "POST",
    body: pagination,
    url: Endpoints.GetAllProducts,
  });
  useEffect(() => {
    if (data && data?.data) {
      setProducts(data.data);
    }
  }, [data]);

  const [searchText, setSearchText] = useState<string>("");
  const [searchPaginationData, setSearchPaginationData] = useState({
    count: 0,
    totalRecords: 0,
  });

  const SearchProducts = async () => {
    const text = searchText.replaceAll("\\", "");
    if (text.length > 0) {
      setCurrentResults("search");
      setSearching(true);

      const r = await PerformRequest<PaginatedResponse<ProductProps[]>>({
        method: "POST",
        route: Endpoints.SearchProduct,
        data: {
          ...pagination,
          page: currentResults === "search" ? pagination.page : 1,
          name: text,
        },
      });

      setSearching(false);
      const { status, message, data } = r;

      if (status === 200) {
        setProducts(data && data.data ? data.data : []);
        setSearchPaginationData({
          count: data.count,
          totalRecords: data.totalRecords,
        });
      } else {
        DefaultErrorNotification("Could not search products!");
      }
    } else {
      setCurrentResults("all");
      setProducts(data?.data ?? []);
      notifications.show({
        message: "Please enter a search term",
        title: "Invalid",
      });
    }
  };
  useEffect(() => {
    if (currentResults === "search") {
      SearchProducts();
    } else {
      reloadData();
    }
  }, [pagination]);

  // CREATE PRODUCT FUNCTIONALITY
  const [isModalOpen, { open: OpenModal, close: CloseModal }] =
    useDisclosure(false);

  const productForm = useForm({
    initialValues: {
      name: "",
      type: "",
      brand: "",
      discount: 0,
      colour: [],
      images: [],
      featuredImage: "",
      description: "",
      prices: [{ size: "", price: 0, stock: 1, id: "initial_price_object" }],
    },
    validate: {
      name: (value) => (value.length < 4 ? "Enter product name" : null),
      type: (value) =>
        !value || value.length === 0 ? "Select product type!" : null,
      colour: (value) =>
        !value || value.length === 0 ? "Enter at least one colour!" : null,
      prices: (values) => {
        const invalidValues = values.filter(
          (v) =>
            v.size.length === 0 ||
            !checkIsValidNumber(v.stock) ||
            !checkIsValidNumber(v.price)
        );
        return invalidValues.length > 0 ? "Ensure prices are filled!" : null;
      },
      description: () =>
        editor?.getText().length === 0 ? "Enter product description" : null,
      featuredImage: (value) =>
        value.length === 0 ? "Select featured image!" : null,
    },
  });
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: productForm.values.description,
    onUpdate: (e) => {
      productForm.setFieldValue("description", e.editor.getHTML());
    },
  });

  const [filesToUpload, setFilesToUpload] = useState<ExtendedFile[]>([]);

  const [filesError, setFilesError] = useState<boolean>(false);

  const [isCreating, setCreating] = useState<boolean>(false);
  const CreateProduct = async () => {
    const {
      name,
      type,
      discount,
      colour,
      featuredImage,
      description,
      brand,
      prices,
    } = productForm.values;
    setFilesError(filesToUpload.length === 0 || featuredImage.length === 0);
    const { hasErrors } = productForm.validate();
    if (filesToUpload.length > 0 && featuredImage.length === 0) {
      DefaultErrorNotification("Please select ONE image to be featured");
    }
    if (!hasErrors) {
      if (filesToUpload.length > 0 && featuredImage.length > 0) {
        setCreating(true);
        const a = await UploadFiles(filesToUpload);
        if (a && a.status === 201) {
          const images = a.data.map((f) => {
            return {
              url: f.path,
              cloudinaryID: f.cloudinaryID,
              id: f.id,
            };
          });
          const featuredImageFile = filesToUpload.find(
            (f) => f.id === featuredImage
          );
          const featuredImageFileIndex = filesToUpload.indexOf(
            featuredImageFile as ExtendedFile
          );

          const r = await PerformRequest<NonPaginatedResponse<any>>({
            method: "POST",
            route: Endpoints.CreateProduct,
            data: {
              name,
              type,
              colour,
              images,
              prices,
              featuredImage: images[featuredImageFileIndex].cloudinaryID,
              discount,
              description,
              brand,
            },
          });

          setCreating(false);
          if (r && r.status) {
            if (r.status === 201) {
              reloadData();
              DefaultSuccessNotification("Product has been created");
              productForm.reset();
              setFilesToUpload([]);
              CloseModal();
            } else {
              DefaultErrorNotification("Could not create product!");
            }
          }
        } else {
          setCreating(false);
          DefaultErrorNotification("Could not upload images!");
        }
      }
    }
  };

  return (
    <div className="products-container">
      <div className="header flex-row width-100 justify-between align-center">
        <Text fw={600} fz="xl" tt="capitalize">
          Products
        </Text>
        <div className="flex-row align-center justify-center">
          <ActionIcon
            onClick={OpenModal}
            w={37}
            h={37}
            loading={isLoading}
            variant="outline"
            mr={10}
          >
            <IconPlus size={16} />
          </ActionIcon>
          <Button
            h={37}
            onClick={() => {
              reloadData();
              setCurrentResults("all");
            }}
            loading={isLoading}
            leftSection={<IconReload size={15} />}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div className="search-row flex-row width-100 align-center">
        <TextInput
          placeholder="Search products..."
          w={180}
          h={37}
          radius="xs"
          value={searchText}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              SearchProducts();
            }
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <ActionIcon
          disabled={searchText.length === 0}
          mx={10}
          onClick={SearchProducts}
          h={37}
          w={37}
          loading={isLoading}
        >
          <IconSearch size={16} />
        </ActionIcon>
        <ActionIcon
          disabled={searchText.length === 0}
          variant="outline"
          onClick={() => {
            setSearchText("");
            setCurrentResults("all");
            setProducts(data?.data ?? []);
          }}
          h={37}
          w={37}
          loading={isLoading}
        >
          <IconX size={16} />
        </ActionIcon>
      </div>
      {!isModalOpen ? (
        <>
          {isLoading || isSearching ? (
            <Group align="center" mt={30} justify="center">
              <Loader size={120} />
            </Group>
          ) : (
            <>
              <Table verticalSpacing="md" withColumnBorders withTableBorder>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Price(s)</Table.Th>
                    <Table.Th>Stock</Table.Th>
                    <Table.Th>Actions</Table.Th>
                  </Table.Tr>
                </Table.Thead>

                <Table.Tbody>
                  {products.map((product, index) => {
                    const prices =
                      product.prices.length === 1
                        ? `₦${getFinancialValueFromNumeric(
                            product.prices[0].price
                          )}`
                        : `₦${getFinancialValueFromNumeric(
                            product.prices
                              .map((p) => p.price)
                              .sort((a, b) => b - a)
                              .reverse()[0]
                          )} - 
                      ₦${getFinancialValueFromNumeric(
                        product.prices
                          .map((p) => p.price)
                          .sort((a, b) => b - a)[0]
                      )}`;
                    const stock = product.prices
                      .map((p) => p.stock)
                      .reduce((prev, acc) => prev + acc, 0);
                    return (
                      <Table.Tr key={product.id}>
                        <Table.Td>
                          {getEllipsisWithString(product.name, 40)}
                        </Table.Td>
                        <Table.Td>{prices}</Table.Td>
                        <Table.Td>{stock}</Table.Td>
                        <Table.Td>
                          <RouteLink to={`/dashboard/product/${product.id}`}>
                            <Button variant="subtle">Details</Button>
                          </RouteLink>
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.Tbody>
              </Table>
              {products?.length === 0 && (
                <Container maxWidth="sm">
                  <Alert title="No results" mt={30}>
                    <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                    <Text>No products found!</Text>
                  </Alert>
                </Container>
              )}
            </>
          )}
        </>
      ) : (
        <Modal
          title="Create New Product"
          opened={isModalOpen}
          onClose={CloseModal}
          centered
          fullScreen
        >
          <div className="product-modal flex-col justify-between">
            <div className="flex-row align-center justify-between width-100">
              <TextInput
                label="Name"
                w="48%"
                placeholder="Enter product name"
                {...productForm.getInputProps("name")}
              />
              <TextInput
                label="Product Brand (optional)"
                w="48%"
                placeholder="Enter product brand"
                {...productForm.getInputProps("brand")}
              />
            </div>
            <br />
            <div className="flex-row align-center justify-between width-100">
              <Select
                data={ProductTypes}
                w="48%"
                label="Product Type"
                placeholder="Select product type"
                {...productForm.getInputProps("type")}
              />
              <NumberInput
                hideControls
                min={0}
                leftSection={<IconPercentage size={18} />}
                placeholder="Enter discount"
                label="Discount (optional)"
                disabled
                w="48%"
                {...productForm.getInputProps("discount")}
              />
            </div>
            <br />
            <TagsInput
              w="100%"
              label="Press Enter to submit a colour"
              placeholder="Enter colours e.g White"
              {...productForm.getInputProps("colour")}
            />
            <Divider w="100%" mt={22} mb={15} />
            <Text fw={500}>Prices</Text>
            {productForm.values.prices.map((priceObject, index) => {
              const ChangeProperty = (
                property: "size" | "price" | "stock",
                value: string | number
              ) => {
                const { prices } = productForm.values;
                const priceIndex = productForm.values.prices.find(
                  (p) => p.id === priceObject.id
                );
                if (priceIndex) {
                  let tempPrices = prices;
                  if (property === "size") {
                    tempPrices[prices.indexOf(priceIndex)].size =
                      value as string;
                    productForm.setFieldValue("prices", tempPrices);
                  } else if (property === "price") {
                    tempPrices[prices.indexOf(priceIndex)].price =
                      value as number;
                    productForm.setFieldValue("prices", tempPrices);
                  } else if (property === "stock") {
                    tempPrices[prices.indexOf(priceIndex)].stock =
                      value as number;
                    productForm.setFieldValue("prices", tempPrices);
                  }
                }
              };
              const RemovePrice = () => {
                const { prices } = productForm.values;

                if (prices.length > 1) {
                  productForm.setFieldValue(
                    "prices",
                    prices.filter((p) => p.id !== priceObject.id)
                  );
                } else {
                  DefaultErrorNotification(
                    "You must have at least ONE variant!",
                    "Unable To Remove"
                  );
                }
              };
              return (
                <div
                  className="flex-row align-end justify-between width-100 price-row"
                  key={priceObject.id}
                >
                  <TextInput
                    placeholder="Size e.g 20 Inches"
                    w="28%"
                    label="Size"
                    onChange={(e) => {
                      ChangeProperty("size", e.target.value);
                    }}
                  />
                  <NumberInput
                    hideControls
                    min={0}
                    leftSection={<IconCurrencyNaira size={18} />}
                    placeholder="Enter Price"
                    w="28%"
                    onChange={(e) => {
                      ChangeProperty("price", e);
                    }}
                    label="Price"
                  />
                  <NumberInput
                    hideControls
                    min={0}
                    leftSection={<IconPackage size={18} />}
                    placeholder="Amount in stock"
                    onChange={(e) => {
                      ChangeProperty("stock", e);
                    }}
                    w="28%"
                    label="Stock"
                  />
                  <ActionIcon
                    variant="subtle"
                    h={35}
                    w={35}
                    onClick={RemovePrice}
                  >
                    <IconTrash size={15} />
                  </ActionIcon>
                </div>
              );
            })}
            <Button
              my={20}
              leftSection={<IconPlus size={15} />}
              style={{
                padding: 0,
                width: 120,
              }}
              h={35}
              variant="outline"
              onClick={() => {
                productForm.setFieldValue("prices", [
                  ...productForm.values.prices,
                  {
                    size: "",
                    id: generateRandomString(12),
                    stock: 0,
                    price: 0,
                  },
                ]);
              }}
            >
              Add Variant
            </Button>
            <Divider w="100%" mt={22} mb={15} />
            <Text fw={500} mb={10}>
              Product Description
            </Text>

            <RichTextEditor
              editor={editor}
              style={{
                borderColor: productForm.errors.description
                  ? "var(--mantine-color-red-6)"
                  : "#CED4DA",
              }}
            >
              <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Underline />
                  <RichTextEditor.Strikethrough />
                  <RichTextEditor.ClearFormatting />
                  <RichTextEditor.Highlight />
                  <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.H1 />
                  <RichTextEditor.H2 />
                  <RichTextEditor.H3 />
                  <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Blockquote />
                  <RichTextEditor.Hr />
                  <RichTextEditor.BulletList />
                  <RichTextEditor.OrderedList />
                  <RichTextEditor.Subscript />
                  <RichTextEditor.Superscript />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Link />
                  <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.AlignLeft />
                  <RichTextEditor.AlignCenter />
                  <RichTextEditor.AlignJustify />
                  <RichTextEditor.AlignRight />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Undo />
                  <RichTextEditor.Redo />
                </RichTextEditor.ControlsGroup>
              </RichTextEditor.Toolbar>

              <RichTextEditor.Content mih={120} />
            </RichTextEditor>
            <Divider w="100%" mt={22} mb={15} />
            <Text mb={10} fw={500}>
              Upload Images
            </Text>
            <Dropzone
              style={{
                borderColor: filesError
                  ? "var(--mantine-color-red-6)"
                  : "#868E96",
              }}
              color="blue"
              onDrop={(files) => {
                const extendedFiles: ExtendedFile[] = files.map((f) => {
                  let obj: any = f;
                  obj.id = generateRandomString(12);

                  return obj;
                });

                setFilesToUpload((prevFiles) => [
                  ...prevFiles,
                  ...extendedFiles,
                ]);
              }}
              onReject={(files) => console.log("rejected files", files)}
              maxSize={5 * 1024 ** 2}
              accept={IMAGE_MIME_TYPE}
            >
              <Group
                justify="center"
                gap="xl"
                mih={100}
                style={{ pointerEvents: "none" }}
              >
                <Dropzone.Accept>
                  <IconUpload
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-blue-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-red-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-dimmed)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Idle>

                <div>
                  <Text size="xl" inline>
                    Drag images here or click to select files
                  </Text>
                  <Text size="sm" c="dimmed" inline mt={7}>
                    Attach as many files as you like, each file should not
                    exceed 5mb
                  </Text>
                </div>
              </Group>
            </Dropzone>
            <motion.div
              initial={false}
              animate={{
                height: filesToUpload.length > 0 ? 200 : 0,
                display: filesToUpload.length > 0 ? "flex" : "none",
              }}
            >
              <Carousel
                my={20}
                slideSize="30%"
                height={200}
                slideGap="md"
                withControls
                withIndicators={false}
                miw="100%"
                maw="100%"
              >
                {filesToUpload.map((file) => {
                  const imgURL = URL.createObjectURL(file);
                  return (
                    <Carousel.Slide key={file.id} maw={280}>
                      <div
                        className="image-container flex-col justify-between"
                        style={{
                          backgroundImage: `url(${imgURL})`,
                        }}
                      >
                        <div className="flex-row align-center">
                          <ActionIcon
                            size="lg"
                            color="white"
                            onClick={() => {
                              setFilesToUpload(
                                filesToUpload.filter((f) => f.id !== file.id)
                              );
                              if (
                                productForm.values.featuredImage === file.id
                              ) {
                                productForm.setFieldValue("featuredImage", "");
                              }
                            }}
                            mr={10}
                          >
                            <IconTrash color="red" size={16} />
                          </ActionIcon>
                          <Radio
                            styles={{
                              radio: {
                                borderColor: "#40C057",
                              },
                            }}
                            classNames={{
                              radio: "pointer",
                            }}
                            icon={CheckIcon}
                            color="green"
                            checked={
                              productForm.values.featuredImage === file.id
                            }
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                productForm.setFieldValue(
                                  "featuredImage",
                                  file.id
                                );
                              }
                            }}
                            size="lg"
                          />
                        </div>
                        {productForm.values.featuredImage === file.id && (
                          <div className="flex-row align-center width-100 justify-center">
                            <Badge
                              color="green"
                              size="lg"
                              w={200}
                              variant="filled"
                            >
                              SELECTED
                            </Badge>
                          </div>
                        )}
                      </div>
                    </Carousel.Slide>
                  );
                })}
              </Carousel>
            </motion.div>

            <Button
              my={30}
              mt={50}
              fullWidth
              onClick={CreateProduct}
              loading={isCreating}
            >
              Create Product
            </Button>
          </div>
        </Modal>
      )}
      <div className="flex-row align-center justify-center width-100">
        <Pagination
          mt={20}
          disabled={isLoading || isSearching}
          value={pagination.page}
          onChange={(e) => setPagination({ ...pagination, page: e })}
          total={
            currentResults === "all"
              ? data && data.totalRecords
                ? data.totalRecords < pagination.limit + 1
                  ? 1
                  : Math.ceil(data.totalRecords / pagination.limit)
                : 1 ?? 10
              : searchPaginationData && searchPaginationData.totalRecords
              ? searchPaginationData.totalRecords < pagination.limit + 1
                ? 1
                : Math.ceil(
                    searchPaginationData.totalRecords / pagination.limit
                  )
              : 1 ?? 10
          }
        />
      </div>
    </div>
  );
}

import { Link, useParams, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import {
  Text,
  Alert,
  Button,
  ActionIcon,
  Loader,
  Badge,
  CopyButton,
  Table,
  Group,
} from "@mantine/core";
import { IconRefresh, IconExclamationCircle } from "@tabler/icons-react";
import dayjs from "dayjs";
import Cookies from "js-cookie";

import { usePerformRequest } from "../../Lib/usePerformRequest";
import {
  ClientProfileResponse,
  NonPaginatedResponse,
} from "../../Lib/Responses";
import { Endpoints } from "../../Lib/Endpoints";

import {
  DefaultSuccessNotification,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";
import "./styles.scss";
const SingleClient: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { clientID } = params;
  const {
    data,
    reloadData,
    status,
    isLoading: isFetchingOrder,
  } = usePerformRequest<NonPaginatedResponse<ClientProfileResponse>>({
    method: "GET",
    url: `${Endpoints.GetSingleClient}/${clientID}`,
  });
  if (status === 401) {
    Cookies.remove("token");
    navigate("/login");
  }

  return (
    <div className="single-order-container">
      <div className="flex-row align-center justify-between width-100">
        <Text fw={600} fz="xl">
          Client Profile
        </Text>
        <ActionIcon onClick={reloadData} loading={isFetchingOrder}>
          <IconRefresh size={19} />
        </ActionIcon>
      </div>
      {isFetchingOrder ? (
        <Group align="center" mt={30} justify="center">
          <Loader size={120} />
        </Group>
      ) : (
        <>
          {data?.data ? (
            <div className="flex-col width-100 body">
              <div className="item-row">
                <Badge radius="xs">Date Registered</Badge>
                <Text className="value" fz={16} fw={500}>
                  {dayjs(data.data.client.dateCreated).format(
                    "dddd DD MMMM YYYY"
                  )}
                </Text>
              </div>
              <div className="item-row">
                <Badge radius="xs">Client Name</Badge>
                <Text className="value" fz={16} fw={500}>
                  {data.data.client.firstname}&nbsp;
                  {data.data.client.lastname}&nbsp;
                </Text>
              </div>
              <div className="item-row">
                <Badge radius="xs">Email</Badge>
                <Text className="value" fw={600} fz="xl">
                  {data.data.client.email}
                </Text>
              </div>
              <div className="item-row">
                <Badge radius="xs">Phone Number</Badge>
                <CopyButton value={data.data.client.phone}>
                  {({ copied, copy }) => (
                    <Text
                      className="value pointer"
                      fz="lg"
                      w="fit-content"
                      color={copied ? "blue" : "black"}
                      onClick={() => {
                        copy();
                        DefaultSuccessNotification("Phone copied to clipboard");
                      }}
                    >
                      {data.data.client.phone}
                    </Text>
                  )}
                </CopyButton>
              </div>

              <div className="item-row">
                <Badge radius="xs">Location</Badge>
                <Text className="value" fw={500} fz="md" tt="capitalize">
                  <b>State: </b> {data.data.client.location.state}
                </Text>
                <Text mt={-10} mb={20} fw={500} fz="md">
                  <b>Address: </b> {data.data.client.location.address}
                </Text>
              </div>
              <Badge radius="xs">Client Orders</Badge>
              {data.data.orders.length > 0 ? (
                <Table
                  mt={4}
                  mb={20}
                  verticalSpacing="md"
                  withColumnBorders
                  withTableBorder
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Product</Table.Th>
                      <Table.Th>Amount</Table.Th>
                      <Table.Th>Unit Price (₦)</Table.Th>
                    </Table.Tr>
                  </Table.Thead>

                  <Table.Tbody>
                    {data.data.orders.map((order) => {
                      return (
                        <Table.Tr key={order.id}>
                          <Table.Td>
                            {dayjs(order.dateCreated).format("DD MMMM YYYY")}
                          </Table.Td>
                          <Table.Td>{order.reference}</Table.Td>
                          <Table.Td>
                            ₦{getFinancialValueFromNumeric(order.amount)}
                          </Table.Td>
                          <Table.Td>
                            <Link to={`/dashboard/order/${order.id}`}>
                              <Button variant="subtle">Details</Button>
                            </Link>
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
              ) : (
                <Container maxWidth="sm">
                  <Alert title="No Orders Found" mt={30}>
                    <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                    <Text>Client has not made any orders</Text>
                  </Alert>
                </Container>
              )}
            </div>
          ) : (
            <Container maxWidth="sm">
              <Alert title="Not Found" mt={30}>
                <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                <Text>Client does not exist on platform</Text>
              </Alert>
            </Container>
          )}
        </>
      )}
    </div>
  );
};

export default SingleClient;

import { useEffect } from "react";

import { useNavigate, Routes, Route } from "react-router-dom";

import Cookies from "js-cookie";
import Navbar from "../../Components/Navbar";
import Products from "../Products";

import SingleProduct from "../SingleProduct";
import Orders from "../Orders";
import SingleOrder from "../SingleOrder";
import Clients from "../Clients";
import SingleClient from "../SingleClient";

import { Endpoints } from "../../Lib/Endpoints";
import { PerformRequest } from "../../Lib/usePerformRequest";

import "./styles.scss";
import { notifications } from "@mantine/notifications";
export default function Dashboard() {
  const navigate = useNavigate();
  const IsAdminAuth = async () => {
    const r = await PerformRequest({
      method: "GET",
      route: Endpoints.GetProfile,
    });
    const { data, status, message } = r;
    if (status !== 200) {
      Cookies.remove("token");
      notifications.show({
        message: "You must log in again",
        title: "Unauthorized",
        color: "red",
      });
      navigate("/login");
    }
  };
  useEffect(() => {
    IsAdminAuth();
  }, []);
  return (
    <>
      <Navbar />
      <div className="dashboard-container">
        <Routes>
          <Route index path="/" element={<Products />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:productID" element={<SingleProduct />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/order/:orderID" element={<SingleOrder />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/client/:clientID" element={<SingleClient />} />
          <Route path="/settings" element={<Products />} />
        </Routes>
      </div>
    </>
  );
}

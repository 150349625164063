// export const baseURL = "http://localhost:8080";
// export const baseURL = "http://192.168.18.4:8080";
export const baseURL = process.env.REACT_APP_BASE_URL;
export const Endpoints = {
  Login: `${baseURL}/admin/login`,
  GetProfile: `${baseURL}/admin/profile/get`,

  GetAllProducts: `${baseURL}/products/all`,
  GetSingleProduct: `${baseURL}/product/get`,
  SearchProduct: `${baseURL}/products/search`,
  CreateProduct: `${baseURL}/admin/product/create`,
  EditProduct: `${baseURL}/admin/product/edit`,
  DeleteProduct: `${baseURL}/admin/product/delete`,

  CreateOrder: `${baseURL}/order/create`,
  GetOrders: `${baseURL}/admin/orders/all`,
  GetSingleOrder: `${baseURL}/admin/order/get`,
  UpdateOrderStatus: `${baseURL}/admin/order/update`,

  GetAllClients: `${baseURL}/admin/clients/all`,
  GetSingleClient: `${baseURL}/admin/client/get`,

  UploadFile: `${baseURL}/file/upload`,
};

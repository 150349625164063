import { useNavigate, Link, useLocation } from "react-router-dom";
import { Center, Tooltip, Stack, rem, Button } from "@mantine/core";
import Cookies from "js-cookie";
import {
  IconHome2,
  IconUser,
  IconSettings,
  IconLogout,
  IconTruckDelivery,
  IconPackage,
} from "@tabler/icons-react";
import Logo from "../../Assets/IMG/Logo.jpg";
import "./styles.scss";
import { useEffect, useState } from "react";

interface NavbarLinkProps {
  icon: typeof IconHome2;
  label: string;
  link: string;

  onClick?: any;
}

function NavbarLink({
  icon: Icon,
  label,

  onClick,
  link,
}: NavbarLinkProps) {
  const location = useLocation();
  const [activePath, setActivePath] = useState<string>("");
  useEffect(() => {
    const path = location.pathname;
    setActivePath(
      path.indexOf("client") !== -1
        ? "clients"
        : path.indexOf("order") !== -1
        ? "orders"
        : path.indexOf("setting") !== -1
        ? "settings"
        : path.indexOf("product") !== -1
        ? "products"
        : "products"
    );
  }, [location]);

  const isLinkActive = false;
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <Link to={`/dashboard/${link}`}>
        <Button
          onClick={onClick}
          className="nav-link"
          variant={link.toLowerCase() === activePath ? "filled" : "subtle"}
        >
          <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
        </Button>
      </Link>
    </Tooltip>
  );
}

const linkes = [
  { icon: IconPackage, label: "Products", link: "products" },
  { icon: IconTruckDelivery, label: "Orders", link: "orders" },
  { icon: IconUser, label: "Clients", link: "clients" },
  // { icon: IconSettings, label: "Settings", link: "settings" },
];

export default function Navbar() {
  const navigate = useNavigate();
  function LogoutAdmin() {
    Cookies.remove("token");
    navigate("/login");
  }
  const links = linkes.map((link, index) => (
    <NavbarLink {...link} key={link.label} />
  ));

  return (
    <div className="nav-absolute">
      <nav className="navbar-container">
        <Center>
          <Link to="/dashboard/products">
            <img src={Logo} className="logo" />
          </Link>
        </Center>

        <div className="nav-main">
          <Stack justify="center" gap={0}>
            {links}
          </Stack>
        </div>

        <Stack justify="center" gap={0}>
          <NavbarLink
            link="logout"
            icon={IconLogout}
            onClick={(e: any) => {
              e.preventDefault();
              LogoutAdmin();
            }}
            label="Logout"
          />
        </Stack>
      </nav>
    </div>
  );
}

import { Link } from "react-router-dom";
import {
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  rem,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import "./styles.scss";

const ForgotPassword: React.FC = () => {
  return (
    <div className="forgot-password-container flex-row align-center justify-center">
      <div className="forgot">
        <Title className="title" fw={500} ta="center">
          Forgot your password?
        </Title>
        <Text c="dimmed" fz="sm" ta="center">
          Enter your email to get a reset link
        </Text>

        <Paper withBorder shadow="md" p={20} radius="md" mt="xl">
          <TextInput
            label="Your email"
            placeholder="e.g me@chapzhair.ng"
            required
          />
          <Group justify="space-between" mt="lg" className="controls">
            <Link to="/login">
              <Anchor c="dimmed" size="sm" className="control">
                <Center inline>
                  <IconArrowLeft
                    style={{ width: rem(12), height: rem(12) }}
                    stroke={1.5}
                  />
                  <Box ml={5}>Back to the login page</Box>
                </Center>
              </Anchor>
            </Link>
            <Button className="control">Reset password</Button>
          </Group>
        </Paper>
      </div>
    </div>
  );
};
export default ForgotPassword;

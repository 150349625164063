import { useEffect, useState } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import {
  Text,
  Alert,
  Button,
  ActionIcon,
  Loader,
  Badge,
  CopyButton,
  Table,
  Select,
  Group,
} from "@mantine/core";
import {
  IconRefresh,
  IconExclamationCircle,
  IconTruck,
  IconLoader3,
  IconNavigationCheck,
  IconCircleFilled,
  IconLoader,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import Cookies from "js-cookie";

import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import { SingleOrderWithClient } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";

import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getFinancialValueFromNumeric,
} from "../../Lib/Methods";
import "./styles.scss";
const SingleOrder: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { orderID } = params;
  const {
    data,
    reloadData,
    status,
    isLoading: isFetchingOrder,
  } = usePerformRequest<NonPaginatedResponse<SingleOrderWithClient>>({
    method: "GET",
    url: `${Endpoints.GetSingleOrder}/${orderID}`,
  });
  if (status === 401) {
    Cookies.remove("token");
    navigate("/login");
  }
  const [deliveryStatus, setDeliveryStatus] = useState<
    "pending" | "delivered" | "transit"
  >("pending");

  const [isUpdatingDelivery, setUpdatingDelivery] = useState<boolean>(false);
  const UpdateDelivery = async () => {
    if (deliveryStatus.length > 0) {
      setUpdatingDelivery(true);
      const r = await PerformRequest<NonPaginatedResponse<any>>({
        method: "PATCH",
        route: Endpoints.UpdateOrderStatus,
        data: {
          orderID,
          delivered: deliveryStatus,
        },
      });
      setUpdatingDelivery(false);
      if (r.status === 200) {
        DefaultSuccessNotification("Order updated!");
        reloadData();
      }
    } else {
      DefaultErrorNotification("Please select a STATUS");
    }
  };
  useEffect(() => {
    if (data && data.data && data.data.order && status === 200) {
      setDeliveryStatus(data.data.order.delivered as any);
    }
  }, [data]);
  return (
    <div className="single-order-container">
      <div className="flex-row align-center justify-between width-100">
        <Text fw={600} fz="xl">
          Order Details
        </Text>
        <ActionIcon onClick={reloadData} loading={isFetchingOrder}>
          <IconRefresh size={19} />
        </ActionIcon>
      </div>
      {isFetchingOrder ? (
        <Group align="center" mt={30} justify="center">
          <Loader size={120} />
        </Group>
      ) : (
        <>
          {data?.data ? (
            <div className="flex-col width-100 body">
              <div className="item-row">
                <Badge radius="xs">Created On</Badge>
                <Text className="value" fz={16} fw={500}>
                  {dayjs(data.data.order.dateCreated).format(
                    "dddd DD MMMM YYYY"
                  )}
                </Text>
              </div>
              <div className="item-row">
                <Badge radius="xs">Client Name</Badge>
                {data.data.order.name && data.data.order.name.length > 0 ? (
                  <Text className="value" fz={16} fw={500}>
                    {data.data.order.name}
                  </Text>
                ) : (
                  <Text className="value" fz={16} fw={500}>
                    {data.data.client.firstname}&nbsp;
                    {data.data.client.lastname}&nbsp;
                  </Text>
                )}
              </div>
              <div className="item-row">
                <Badge radius="xs">Client Email</Badge>
                <Text className="value" fz={16} fw={500}>
                  {data.data.client
                    ? data.data.client.email
                    : data.data.order.clientID}
                </Text>
              </div>
              <div className="item-row">
                <Badge radius="xs">Amount</Badge>
                <Text className="value" fw={600} fz="xl">
                  ₦{getFinancialValueFromNumeric(data.data.order.amount)}
                </Text>
              </div>
              <div className="item-row">
                <Badge radius="xs">Paystack Reference</Badge>
                <CopyButton value={data.data.order.reference}>
                  {({ copied, copy }) => (
                    <Text
                      className="value pointer"
                      w="fit-content"
                      fz="lg"
                      color={copied ? "blue" : "black"}
                      onClick={() => {
                        copy();
                        DefaultSuccessNotification(
                          "Reference copied to clipboard"
                        );
                      }}
                    >
                      {data.data.order.reference}
                    </Text>
                  )}
                </CopyButton>
              </div>
              <div className="item-row">
                <Badge radius="xs">Transaction Status</Badge>
                <Badge
                  className="value"
                  size="xl"
                  fz="sm"
                  mt={10}
                  color={data.data.order.status === "success" ? "green" : "red"}
                >
                  {data.data.order.status}
                </Badge>
              </div>
              <Badge radius="xs">Items in Order</Badge>

              <Table
                mt={4}
                mb={20}
                verticalSpacing="md"
                withColumnBorders
                withTableBorder
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Product</Table.Th>
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Unit Price (₦)</Table.Th>
                  </Table.Tr>
                </Table.Thead>

                <Table.Tbody>
                  {data.data.order.products.map((product) => {
                    return (
                      <Table.Tr key={product.id}>
                        <Table.Td>
                          <Text>
                            <b>
                              <IconCircleFilled size={10} />
                              {product.size}
                              <IconCircleFilled size={10} />
                            </b>
                            <Link to={`/dashboard/product/${product.id}`}>
                              <Text c="blue" td="underline">
                                {product.name}
                              </Text>
                            </Link>
                          </Text>
                        </Table.Td>
                        <Table.Td>
                          <Text>{product.count}</Text>
                        </Table.Td>
                        <Table.Td>
                          <Text>{product.unitPrice}</Text>
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.Tbody>
              </Table>
              <div className="item-row">
                <Badge radius="xs">Delivery Information</Badge>
                <Text className="value" fw={500} fz="md" tt="capitalize">
                  <b>Country: </b> {data.data.order.location.country}
                </Text>
                <Text className="value" fw={500} fz="md" tt="capitalize">
                  <b>State/City: </b> {data.data.order.location.state}
                </Text>
                <Text mb={20} fw={500} fz="md">
                  <b>Address: </b> {data.data.order.location.address}
                </Text>
              </div>
              <div className="flex-row align-end">
                <Select
                  label="Delivery Status"
                  w={250}
                  placeholder="Select delivery status of order"
                  value={deliveryStatus}
                  onChange={(e) => {
                    setDeliveryStatus(e as any);
                  }}
                  mr={5}
                  data={[
                    {
                      label: "Delivered",
                      value: "delivered",
                    },
                    {
                      label: "Pending",
                      value: "pending",
                    },
                    {
                      label: "In Transit",
                      value: "transit",
                    },
                  ]}
                />
                {deliveryStatus === "transit" ? (
                  <IconTruck
                    style={{ marginBottom: 5 }}
                    color="var(--mantine-color-blue-7)"
                    size={23}
                  />
                ) : deliveryStatus === "pending" ? (
                  <IconLoader
                    style={{ marginBottom: 5 }}
                    color="var(--mantine-color-orange-7)"
                    size={23}
                  />
                ) : (
                  deliveryStatus === "delivered" && (
                    <IconNavigationCheck
                      style={{ marginBottom: 5 }}
                      color="var(--mantine-color-green-7)"
                      size={23}
                    />
                  )
                )}
              </div>
              <Button
                disabled={["", null, undefined].includes(deliveryStatus)}
                mt={20}
                onClick={UpdateDelivery}
                loading={isUpdatingDelivery}
                w={250}
              >
                Update
              </Button>
              {data.data.order.deliveryDate && (
                <div className="item-row">
                  <Badge radius="xs" mt={20}>
                    Delivery Date
                  </Badge>
                  <Text className="value" size="xl" fz="sm" mt={10}>
                    {dayjs(data.data.order.deliveryDate).format(
                      "dddd, DD MMMM YYYY"
                    )}{" "}
                    at{" "}
                    <b>
                      {dayjs(data.data.order.deliveryDate).format("HH:MM A")}
                    </b>
                  </Text>
                </div>
              )}
            </div>
          ) : (
            <Container maxWidth="sm">
              <Alert title="Not Found" mt={30}>
                <IconExclamationCircle color="var(--mantine-color-blue-6)" />
                <Text>Order not found on platform</Text>
              </Alert>
            </Container>
          )}
        </>
      )}
    </div>
  );
};

export default SingleOrder;

import { Skeleton } from "@mantine/core";

interface LoaderProps {
  count?: number;
}
export default function LoaderComp({ count }: LoaderProps) {
  const arr = new Array(count ?? 10);
  return (
    <>
      {arr.map((e, index) => {
        return <Skeleton height={8} my={6} radius="xl" key={index} />;
      })}
    </>
  );
}

import { Link, useNavigate } from "react-router-dom";

import { Text, Button, Paper, TextInput, PasswordInput } from "@mantine/core";

import { useForm } from "@mantine/form";

import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../Lib/Methods";

import "./styles.scss";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { useState } from "react";
import { LoginResponse, NonPaginatedResponse } from "../../Lib/Responses";
import Cookies from "js-cookie";

interface LoginFormProps {
  email: string;
  password: string;
}

export default function Login() {
  const navigate = useNavigate();
  const loginForm = useForm<LoginFormProps>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Please enter a valid email!",
      password: (value) =>
        value.length >= 6 ? null : "Your password is at least SIX characters",
    },
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const LoginAdmin = async () => {
    const { hasErrors } = loginForm.validate();
    if (!hasErrors) {
      const { email, password } = loginForm.values;
      setLoading(true);
      const r = await PerformRequest<NonPaginatedResponse<LoginResponse>>({
        method: "POST",
        route: Endpoints.Login,
        data: { email: email.toLowerCase(), password },
      }).catch(() => {
        setLoading(false);
      });
      setLoading(false);
      if (r) {
        const { status, message, data } = r;
        if (status === 200) {
          DefaultSuccessNotification("Logged in successfully!", "Success");
          Cookies.set("token", data?.data?.token ?? "");
          navigate("/dashboard/products");
        } else {
          DefaultErrorNotification(message);
        }
      }
    }
  };
  return (
    <div className="login-container">
      <Paper className="login" shadow="sm">
        <Text fw={500} fz="xl">
          Login To Continue
        </Text>
        <TextInput
          name="email"
          placeholder="Enter email address"
          w="100%"
          label="Email"
          {...loginForm.getInputProps("email")}
        />
        <PasswordInput
          placeholder="Enter password"
          w="100%"
          label="Password"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              LoginAdmin();
            }
          }}
          {...loginForm.getInputProps("password")}
        />
        <Button
          fullWidth
          loading={isLoading}
          onClick={() => {
            LoginAdmin();
          }}
        >
          Login
        </Button>
        <Link to="/forgot">
          <Text c="dimmed" fz="sm">
            Forgot Password?
          </Text>
        </Link>
      </Paper>
    </div>
  );
}
